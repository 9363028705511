import { createDefaultGeoLocation } from './createDefaultGeoLocation';
import { EGetLocationsActionType, EPopupActionType } from '../../actions/geoLocation';
import { IGeoLocationState, ELocationsStatus } from '../../types/geoLocation';
import { TReduxActions } from '../../types/redux';

export function geoLocationReducer(
  state: IGeoLocationState = createDefaultGeoLocation(),
  action: TReduxActions,
): IGeoLocationState {
  switch (action.type) {
    case EPopupActionType.Open: {
      return {
        ...state,
        popup: {
          ...state.popup,
          isOpen: true,
          type: action.payload,
        },
      };
    }

    case EPopupActionType.Close: {
      return {
        ...state,
        popup: {
          ...state.popup,
          isOpen: false,
        },
      };
    }

    case EGetLocationsActionType.Loading: {
      return {
        ...state,
        locations: {
          ...state.locations,
          status: ELocationsStatus.Loading,
        },
      };
    }

    case EGetLocationsActionType.Failed: {
      return {
        ...state,
        locations: {
          ...state.locations,
          status: ELocationsStatus.Failed,
        },
      };
    }

    case EGetLocationsActionType.Succeed: {
      return {
        ...state,
        locations: {
          status: ELocationsStatus.Succeed,
          items: action.payload,
        },
      };
    }

    default:
      return state;
  }
}
