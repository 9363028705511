import { ca } from '@cian/analytics';

/**
 * Требования для аналитики:
 * https://conf.cian.tech/pages/viewpage.action?pageId=1255737753
 */
export function trackRegionSelect() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'mobile_main_page',
    action: 'click',
    label: 'regionOffers',
  });
}
