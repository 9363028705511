import * as React from 'react';

// TODO: не импортить из browser
import * as styles from './SeoRegions.css';
import { SeoRegion } from './components/SeoRegion';
import { setLocationCookies } from '../../../browser/utils/location';

export interface ISeoRegion {
  title: string;
  href: string;
}

export interface ISeoRegionsProps {
  items: ISeoRegion[];
  onRegionClick(): void;
}

export interface ISeoRegionsState {
  isOpen: boolean;
}

export class SeoRegions extends React.PureComponent<ISeoRegionsProps, ISeoRegionsState> {
  public state: ISeoRegionsState = {
    isOpen: true,
  };

  public componentDidMount() {
    this.setState({
      isOpen: false,
    });
  }

  public render() {
    const { items } = this.props;
    const { isOpen } = this.state;

    const itemsToShow = isOpen ? items : items.slice(0, 5);

    return (
      <ul className={styles['container']}>
        {itemsToShow.map(item => (
          <SeoRegion
            key={`region_${item.title}`}
            title={item.title}
            href={item.href}
            onClick={() => this.handleRegionClick(item.href)}
          />
        ))}
        {items > itemsToShow && (
          <li className={styles['more']}>
            <button onClick={this.handleShowMoreClick}>Показать все регионы</button>
          </li>
        )}
      </ul>
    );
  }

  private handleRegionClick = (href: string) => {
    if (href.includes('//www.')) {
      setLocationCookies(1, 'Москва и МО');
    }

    this.props.onRegionClick();
  };

  private handleShowMoreClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();

    this.setState({
      isOpen: true,
    });
  };
}
