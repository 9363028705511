import { IExperiment } from '../../types/experiments';
import { IApplicationState } from '../../types/redux';
import { getExperiment } from '../../utils/experiments';

export const selectExperiments = (state: IApplicationState): IExperiment[] => state.experiments;

export function isExperimentActive(experiments: IExperiment[], experimentName: string, groupName: string): boolean {
  const experiment = getExperiment(experiments, experimentName);

  if (!experiment) {
    return false;
  }

  return experiment.groupName === groupName;
}
