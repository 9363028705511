import * as React from 'react';

import * as styles from './Carousel.css';

interface ICarouselProps {
  children: React.ReactNode;
}

export const Carousel: React.FC<ICarouselProps> = ({ children }) => {
  return <div className={styles['container']}>{children}</div>;
};
