import { combineReducers } from 'redux';

import { catalogsReducer } from './catalogs';
import { experimentsReducer } from './experiments';
import { geoLocationReducer } from './geoLocation';
import { halfScreenBannerReducer } from './halfScreenBanner';
import { newOfferFormReducer } from './newOfferForm';
import { pageReducer } from './page';
import { pikPromoBannerReducer } from './pikPromoBanner';
import { quickFiltersReducer } from './quickFilters';
import { quickLinksReducer } from './quickLinks';
import { regionsReducer } from './regions';
import { seoReducer } from './seo';
import { seoCrossLinksReducer } from './seoCrossLinks';
import { servicesReducer } from './services';
import { subdomainReducer } from './subdomain';
import { userReducer } from './user';
import { IApplicationState, TReduxActions } from '../types/redux';

export const reducers = combineReducers<IApplicationState, TReduxActions>({
  user: userReducer,
  geoLocation: geoLocationReducer,
  subdomain: subdomainReducer,
  quickFilters: quickFiltersReducer,
  quickLinks: quickLinksReducer,
  newOfferForm: newOfferFormReducer,
  regions: regionsReducer,
  seo: seoReducer,
  services: servicesReducer,
  catalogs: catalogsReducer,
  page: pageReducer,
  halfScreenBanner: halfScreenBannerReducer,
  experiments: experimentsReducer,
  pikPromoBanner: pikPromoBannerReducer,
  сrossLinks: seoCrossLinksReducer,
});
