import { connect } from 'react-redux';

import { ISeoDescriptionProps, SeoDescription } from '../../components/SeoDescription';
import { IApplicationState } from '../../types/redux';

type TSeoDescriptionStateProps = Pick<ISeoDescriptionProps, 'title' | 'text'>;

export function mapStateToProps(state: IApplicationState): TSeoDescriptionStateProps {
  return {
    title: state.seo.h1,
    text: state.seo.text,
  };
}

export const SeoDescriptionContainer = connect(mapStateToProps)(SeoDescription);
