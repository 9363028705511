import { TReduxActions } from '../../types/redux';
import { IRegions } from '../../types/regions';

export const defaultState = {
  items: [],
};

export function regionsReducer(state: IRegions = defaultState, action: TReduxActions): IRegions {
  switch (action.type) {
    default:
      return state;
  }
}
