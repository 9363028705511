import * as React from 'react';

import * as styles from './LocationLinks.css';

interface ILocationLinksProps {
  children: React.ReactNode;
}

export const LocationLinks: React.FC<ILocationLinksProps> = ({ children }) => {
  return <ul className={styles['list']}>{children}</ul>;
};

LocationLinks.displayName = 'LocationLinks';
