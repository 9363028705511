import { ca } from '@cian/analytics';

export function trackModalOpen() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'search_string',
    action: 'open',
    label: '',
  });
}
