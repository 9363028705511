import { ErrorLogComponent } from '@cian/error-log-component';
import { UiKitContextProvider } from '@cian/ui-kit/context';
import * as React from 'react';
import { Provider } from 'react-redux';

import { normalizeDeviceType } from './utils/normalizeDeviceType';
import { IApplicationContext } from '../../types/application';
import { TReduxStore } from '../../types/redux';
import { ApplicationContext } from '../ApplicationContext';
import { Page } from '../Page';

export interface IApplicationProps {
  context: IApplicationContext;
  reduxStore: TReduxStore;
}

export const Application: React.FC<IApplicationProps> = ({ reduxStore, context }) => {
  return (
    <ErrorLogComponent logger={context.logger}>
      <ApplicationContext.Provider value={context}>
        <UiKitContextProvider deviceType={normalizeDeviceType(context.custom.deviceType)}>
          <Provider store={reduxStore}>
            <Page />
          </Provider>
        </UiKitContextProvider>
      </ApplicationContext.Provider>
    </ErrorLogComponent>
  );
};
