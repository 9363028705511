import * as R from 'ramda';

import { ILocation } from '../../../types/geoLocation';

const PRIMARY_LOCATIONS: number[] = [1, 4593, 2, 4588];

export function getPopularLocations(locations: ILocation[]): ILocation[][] {
  return [
    [
      ...(PRIMARY_LOCATIONS.map(locationId => locations.find(location => location.id === locationId)).filter(
        Boolean,
      ) as ILocation[]),
    ],
  ];
}

export function getSortedLocationsByName(locations: ILocation[]): ILocation[] {
  const handler = R.sortBy<ILocation>(R.compose(R.toLower, R.prop('displayName')));

  return [...handler(locations)];
}

export function getSortedLocationsByIndex(locations: ILocation[], value: string): ILocation[] {
  const normalizedValue = normalizeSearchValue(value);
  const comparator = (o1: ILocation, o2: ILocation) =>
    normalizeSearchValue(o1.displayName).indexOf(normalizedValue) -
    normalizeSearchValue(o2.displayName).indexOf(normalizedValue);

  return [...locations].sort(comparator);
}

export function getFilteredLocations(locations: ILocation[], value: string): ILocation[] {
  const filteredLocations = locations.filter(item =>
    normalizeSearchValue(item.displayName).includes(normalizeSearchValue(value)),
  );

  return getSortedLocationsByIndex(filteredLocations, value);
}

export function normalizeSearchValue(value: string): string {
  return value.toLowerCase().replace(/\s/g, '').replace('ё', 'е');
}
