/* eslint-disable @typescript-eslint/no-explicit-any */
import { compose, createStore as _createStore, applyMiddleware, CombinedState } from 'redux';
import thunk, { ThunkDispatch } from 'redux-thunk';

import { reducers } from '../reducers';
import { IApplicationContext } from '../types/application';
import { IApplicationState, TApplicationStateInitial, TReduxActions, TReduxStore } from '../types/redux';

interface IThunkExt {
  dispatch: ThunkDispatch<IApplicationState, IApplicationContext, TReduxActions>;
}

let storeReminder: TReduxStore;

/**
 * Хелпер для создания Redux стора
 * @param initialState IApplicationState - предзагруженное состояние
 */
export function createReduxStore(
  initialState: TApplicationStateInitial,
  context: IApplicationContext,
  debugEnabled: boolean,
) {
  /* istanbul ignore next */
  const composeEnhancers = (() => {
    if (process.env.NODE_ENV === 'development' && debugEnabled) {
      const { __REDUX_DEVTOOLS_EXTENSION_COMPOSE__ } = window;

      if (__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
        return __REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          name: `mobile-mainpage-frontend: ${document.title}`,
        } as any);
      }
    }

    return compose;
  })();

  const storeEnchancers = composeEnhancers(applyMiddleware(thunk.withExtraArgument(context)));

  const createStore = (): TReduxStore =>
    _createStore<CombinedState<IApplicationState>, TReduxActions, IThunkExt, {}>(
      reducers,
      initialState,
      storeEnchancers,
    );

  /* istanbul ignore next */
  if (process.env.NODE_ENV === 'development' && debugEnabled) {
    if (!storeReminder) {
      storeReminder = createStore();
    }

    storeReminder.replaceReducer(reducers);

    return storeReminder;
  }

  return createStore();
}
