import { UIText1 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './QuickFilters.css';
import { getQuickFiltersIconByKey } from './getQuickFiltersIconByKey';
import { IQuickFiltersBlock } from '../../types/quickFilters';

export interface IQuickFilters {
  filters: IQuickFiltersBlock[];
  trackClickQuickFilter(key: string): void;
}

export const QuickFilters: React.FC<IQuickFilters> = ({ filters, trackClickQuickFilter }) => {
  return (
    <div className={styles['filters']}>
      {filters.map((filter: IQuickFiltersBlock) => (
        <a
          key={filter.key}
          href={filter.queryString}
          className={styles['filter']}
          onClick={() => trackClickQuickFilter(filter.key)}
        >
          <img className={styles['filter-icon']} src={getQuickFiltersIconByKey(filter.key)} />
          <UIText1>{filter.title}</UIText1>
        </a>
      ))}
    </div>
  );
};
