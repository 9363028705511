import * as React from 'react';

import * as styles from './SeoDescription.css';
import { CollapsedBlock } from '../../../../packages/CollapsedBlock';

export interface ISeoDescriptionProps {
  title: string;
  text: string;
}

export const SeoDescription: React.FC<ISeoDescriptionProps> = ({ title, text }) => {
  return (
    <section className={styles['container']}>
      <h1 className={styles['title']}>{title}</h1>
      <CollapsedBlock className={styles['description']}>
        {/* eslint-disable-next-line react/no-danger */}
        <span dangerouslySetInnerHTML={{ __html: text }} />
      </CollapsedBlock>
    </section>
  );
};
