import { ca } from '@cian/analytics';

import { IProduct } from '../../types/tracking';

export const trackRecommendationsView = (products?: IProduct[]) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'similar_objects',
    action: 'show_similar_offers',
    label: 'user-based',
    products,
  });
};

export const trackRecommendationsTrapView = (category: string = 'Mortgage') => {
  ca('eventSite', {
    name: 'oldevent',
    category,
    action: 'show_sopr',
    label: 'recs_user_based',
  });
};

export const trackRecommendationItemClick = (products?: IProduct[]) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'similar_objects',
    action: 'click_similar_offers',
    label: 'user-based',
    products,
  });
};

export const trackRecommendationTrapClick = (category: string = 'Mortgage') => {
  ca('eventSite', {
    name: 'oldevent',
    category,
    action: 'click',
    label: 'recs_user_based',
  });
};
