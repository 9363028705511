import { UIText1, UIText2 } from '@cian/ui-kit/typography';
import * as React from 'react';

import * as styles from './styles.css';
import { Ticker } from '../Ticker';

export interface IPikPromoBannerProps {
  onClick(): void;
  disclaimer: string | null;
  hitUrl: string;
}

export function PikPromoBanner({ onClick, disclaimer, hitUrl }: IPikPromoBannerProps) {
  return (
    <div className={styles['container']}>
      <a data-testid="hitUrl" href={hitUrl} className={styles['promo-block']} onClick={onClick}>
        <UIText1 color="white_100" fontWeight="bold">
          Серия плюс
        </UIText1>
        <UIText2 color="white_100">
          Линейка проектов
          <br />с новым уровнем комфорта от ПИК
        </UIText2>
        {disclaimer && (
          <div data-testid="disclaimer" className={styles['disclaimer']}>
            <Ticker text={disclaimer} />
          </div>
        )}
      </a>
    </div>
  );
}
