import { ca } from '@cian/analytics';

import { TDevice } from '../../types/device';
import { createCianAnalyticsPageWithExtra } from '../../utils/createCianAnalyticsPageWithExtra';

interface ITrackPromoBannerProps {
  label: string;
  deviceType: TDevice;
  queryString: string;
}

export const getTrackPromoBanner = ({ label, deviceType, queryString }: ITrackPromoBannerProps) => {
  return () =>
    ca('eventSite', {
      name: 'oldevent',
      category: 'mobile_main_page',
      action: 'click_promo',
      label,
      page: createCianAnalyticsPageWithExtra({ deviceType, extra: { queryString } }),
    });
};
