import { connect, TAdFoxBannerSizeProps } from '@cian/adfox-component';
import { InitialAdfoxAnalyticsPayload, PParams, withAdfoxPostViewAnalytics } from '@cian/postview-analytics-utils';
import { useBooleanState } from '@cian/react-utils';
import * as React from 'react';

import { OwnTemplates } from './OwnTemplates';
import { usePuids } from './helpers';

export const AdfoxBannerConnected = connect(OwnTemplates);

export interface IAdfoxBannerContainerProps {
  postViewAnalyticsData: Pick<
    InitialAdfoxAnalyticsPayload,
    'place' | 'place__DEPRECATED' | 'name__DEPRECATED' | 'extra__DEPRECATED' | 'isSp'
  >;
  pParams: PParams;
  size: TAdFoxBannerSizeProps;
  position?: number;
  galleryPosition?: number;
}

export function AdfoxBannerContainer({
  size,
  pParams,
  postViewAnalyticsData,
  position,
  galleryPosition,
}: IAdfoxBannerContainerProps) {
  const puids = usePuids();

  const { state: isHidden, setTrue: hideBanner } = useBooleanState(false);

  return React.useMemo(() => {
    if (isHidden) {
      return null;
    }

    const AdfoxWithPostView = withAdfoxPostViewAnalytics(AdfoxBannerConnected, {
      position,
      galleryPosition,
      ...postViewAnalyticsData,
    });

    return <AdfoxWithPostView {...size} params={{ ...pParams, ...puids }} onStub={hideBanner} onError={hideBanner} />;
  }, [isHidden]);
}
