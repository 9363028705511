import { ca } from '@cian/analytics';

export const categoryMap: { [key: string]: string } = {
  flat_sale: 'main_sale',
  flat_rent: 'main_rent',
  newbuilding: 'main_newobjects',
  suburban: 'main_suburban',
  commercial: 'main_commercial',
  mortgage: 'main_mortgage',
  posytochno: 'main_posytochno',
  daily_rent: 'main_posytochno',
  suburban_builders_projects: 'main_countryside_builders',
};

export const trackClickQuickFilter = (key: string) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'mobile_main_page',
    action: 'click',
    label: categoryMap[key],
  });
};
