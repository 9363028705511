import { IQuickFilters } from '../../types/quickFilters';
import { TReduxActions } from '../../types/redux';

export const defaultState = {
  blocks: [],
};

export function quickFiltersReducer(state: IQuickFilters = defaultState, action: TReduxActions): IQuickFilters {
  switch (action.type) {
    default:
      return state;
  }
}
