import * as React from 'react';
import { useSelector } from 'react-redux';

import { getQuickLinksTracker } from './tracking';
import { keyMap } from '../../assets/quickLinks';
import { QuickLinks, QuickLink } from '../../components/QuickLinks';
import { Section } from '../../components/Section';
import { IQuickLinksBlock } from '../../types/quickLinks';
import { IApplicationState } from '../../types/redux';

export const selector = (state: IApplicationState): IQuickLinksBlock[] => state.quickLinks.items;

export const QuickLinksContainer: React.FC = () => {
  const items = useSelector(selector);

  if (items.length === 0) {
    return null;
  }

  return (
    <Section title="Быстрые ссылки">
      {items.map(quickLinks => (
        <QuickLinks
          key={`quickLinks_${quickLinks.title}`}
          backgroundImage={keyMap[quickLinks.key]}
          url={quickLinks.url}
          title={quickLinks.title}
          onClick={getQuickLinksTracker({ url: quickLinks.url })}
        >
          {quickLinks.items.map(quickLink => (
            <QuickLink
              key={`quickLink_${quickLinks.title}_${quickLink.title}`}
              title={quickLink.title}
              subtitle={quickLink.count}
              href={quickLink.url}
              onClick={getQuickLinksTracker({ url: quickLink.url })}
            />
          ))}
        </QuickLinks>
      ))}
    </Section>
  );
};
