import * as React from 'react';

interface IWithChangeOrienatationProps {
  children: JSX.Element;
  handler(): void;
}

export const WithChangeOrientation = ({ children, handler }: IWithChangeOrienatationProps) => {
  React.useEffect(() => {
    const mediaQuery = window.matchMedia('(orientation: portrait)');

    mediaQuery.addListener(handler);

    handler();

    return () => mediaQuery.removeListener(handler);
  }, []);

  return children;
};
