import { UIText3 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './styles.css';
import { TMainpageBrandContext } from '../../../types/adfox';
import { Ticker } from '../../Ticker';

type TBrandBannerProps = {
  context: TMainpageBrandContext;
};

export function BrandBanner({ context }: TBrandBannerProps) {
  const { url, bgImageUrl, logoImageUrl, actionText, note, label, name } = context;

  return (
    <div className={styles['container']} style={{ backgroundImage: `url(${bgImageUrl})` }} data-testid="BrandBanner">
      <a href={url} target="_blank" rel="noopener noreferrer nofollow" className={styles['link']}>
        <div className={styles['main']}>
          <div className={styles['content']}>
            {logoImageUrl && (
              <div className={styles['logo']}>
                <img src={logoImageUrl} alt={name} className={styles['logo-image']} />
              </div>
            )}
            <div className={styles['content-text']}>
              <UIText3 color="white_100">{actionText}</UIText3>
            </div>
          </div>
          {label && <div className={styles['label']}>{label}</div>}
        </div>
        {note && (
          <div data-testid="disclaimer" className={styles['disclaimer']}>
            <Ticker paddingLeft={8} text={note} />
          </div>
        )}
      </a>
    </div>
  );
}
