import { ca } from '@cian/analytics';

export const trackServiceClick = (label: string) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'mobile_main_page',
    action: 'click_services',
    label,
  });
};

export const trackServiceShow = (label: string) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'mobile_main_page',
    action: 'show_sopr_services',
    label,
  });
};
