import * as React from 'react';
import { useSelector } from 'react-redux';

import { trackServiceClick, trackServiceShow } from './tracking';
import { openCianGptPopup } from './utils/openCianGptPopup';
import { Carousel } from '../../components/Carousel';
import { Service } from '../../components/Service';
import { selectServices } from '../../selectors/services';

export const ServicesContainer: React.FC = () => {
  const services = useSelector(selectServices);

  if (!services.length) {
    return null;
  }

  return (
    <Carousel>
      {services.map(({ key, title, url, image }) => (
        <Service
          key={`service-${key}`}
          title={title}
          url={url}
          image={image}
          onShow={() => trackServiceShow(key)}
          onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
            if (key === 'chat_bot') {
              event.preventDefault();
              openCianGptPopup();
            }

            trackServiceClick(key);
          }}
        />
      ))}
    </Carousel>
  );
};
