import { initConfigUnsafe } from '@cian/config/browser';
import { init as initGlobalBehaviors } from '@cian/global-behaviors/browser';
import { init as initHttpApi } from '@cian/http-api/browser';
import { initLogger } from '@cian/logger/browser';
import { initMicrofrontend } from '@cian/mf-registry-legacy/browser';
import { init as initTelemetry } from '@cian/telemetry/browser';
import { init as initWeb } from '@cian/web/browser';

export function initDependencies() {
  initConfigUnsafe();
  initLogger();
  initTelemetry();
  initHttpApi();
  initWeb();
  initGlobalBehaviors();
  initMicrofrontend();
}
