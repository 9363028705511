import { EGetLocationsActionType } from './types';
import { ResponseError } from '../../errors/responseError';
import { prepareLocations } from '../../mappers/prepareLocations';
import { actionGenerator } from '../../redux/actionGenerator';
import {
  fetchSearchRegionsCities,
  TSearchRegionsCitiesResponse,
} from '../../repositories/monolith-python/v1/search-regions-cities';
import { ILocation } from '../../types/geoLocation/locations';
import { TThunkAction } from '../../types/redux/thunk';

const setStatusLoading = actionGenerator<EGetLocationsActionType.Loading>(EGetLocationsActionType.Loading);
const setStatusSucceed = actionGenerator<EGetLocationsActionType.Succeed, ILocation[]>(EGetLocationsActionType.Succeed);
const setStatusFailed = actionGenerator<EGetLocationsActionType.Failed>(EGetLocationsActionType.Failed);

export function getLocations(): TThunkAction<Promise<ILocation[]>> {
  return async (dispatch, getState, { httpApi, logger }) => {
    dispatch(setStatusLoading());

    const { subdomain } = getState();

    try {
      const locationsData: TSearchRegionsCitiesResponse = await fetchSearchRegionsCities({
        httpApi,
        parameters: {
          text: '',
        },
        config: {
          subdomain,
          requestConfig: {
            withCredentials: false,
          },
        },
      });

      if (locationsData.statusCode !== 200) {
        dispatch(setStatusFailed());

        const error = new ResponseError({
          domain: 'geoLocation#getLocations',
          message: locationsData.response.message,
          details: {
            error: locationsData.response.errors,
          },
        });

        logger.error(error);

        throw error;
      }

      const {
        response: {
          data: { items },
        },
      } = locationsData;
      if (!items) {
        dispatch(setStatusFailed());

        const error = new ResponseError({
          domain: 'geoLocation#getLocations',
          message: 'Unexpectedly empty locations list',
        });

        logger.error(error);

        throw error;
      }

      const locations: ILocation[] = prepareLocations(items);

      dispatch(setStatusSucceed(locations));

      return locations;
    } catch (ex) {
      dispatch(setStatusFailed());

      const error = new ResponseError({
        domain: 'geoLocation#getLocations',
        message: ex.message,
      });

      logger.error(error);

      throw error;
    }
  };
}
