import * as React from 'react';

import { SeoCrossLinksGroup } from './SeoCrossLinksGroup';
import { ICrossLinks } from '../../types/crossLinks';
import { Section } from '../Section';

interface ISeoCrossLinksProps {
  items: ICrossLinks[];
}

export function SeoCrossLinks({ items }: ISeoCrossLinksProps) {
  return (
    <Section indent="L" data-name="SeoCrossLinks">
      {items.map((item, index) => {
        const id = `SeoCrossLinksItem_${index}`;

        return <SeoCrossLinksGroup id={id} key={id} {...item} />;
      })}
    </Section>
  );
}
