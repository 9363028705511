/*
  Функция, возвращающая длительность css анимации для бегающей строки.
  Рассчитана на то, что изначально весь текст бегущей строки скрыт за областью видимости
*/
export function getAnimationDuration(
  tickerContainer: HTMLElement | null,
  wrapperContainer: HTMLElement | null,
): number {
  if (!tickerContainer || !wrapperContainer) {
    return 0;
  }

  const distance = tickerContainer.offsetWidth;

  if (distance <= wrapperContainer.offsetWidth) {
    return 0;
  }

  const speed = 80;
  const time = Math.ceil(distance / speed);

  return Math.max(0, time);
}
