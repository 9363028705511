import { Spinner } from '@cian/ui-kit/loader';
import * as React from 'react';

import * as styles from './ContentFallback.css';

export const ContentFallback: React.FC = () => {
  return (
    <div className={styles['container']} data-testid="ContentFallback">
      <Spinner size={24} color="primary_100" />
    </div>
  );
};

ContentFallback.displayName = 'ContentFallback';
