import * as React from 'react';
import { useSelector } from 'react-redux';

import { trackClickQuickFilter } from './tracking';
import { QuickFilters } from '../../components/QuickFiltersRedesigned';
// Экспериментируем по частям
// import { QuickFilters as QuickFiltersRedesigned } from '../../components/QuickFiltersRedesignedV3';
import { IApplicationState } from '../../types/redux';

const QuickFiltersContainerComponent: React.FC = () => {
  const quickFiltersBlocks = useSelector(({ quickFilters }: IApplicationState) => quickFilters.blocks);

  return <QuickFilters filters={quickFiltersBlocks} trackClickQuickFilter={trackClickQuickFilter} />;
};

export const QuickFiltersContainer = React.memo(QuickFiltersContainerComponent);
