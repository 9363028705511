import { getGlobalEventsLogs } from '@cian/events-log';

export const EVENT_VALUE = {
  source: 'Service',
  pageType: 'Home',
};

export const openCianGptPopup = () => {
  const globalLog = getGlobalEventsLogs();

  globalLog.produce('cianGptPopup', { type: 'open', value: EVENT_VALUE });
};
