import { Button } from '@cian/ui-kit/button';
import * as React from 'react';

import * as styles from './ErrorBoundary.css';

interface IErrorBoundaryProps {
  onRetry(): void;
}

export const ErrorBoundary: React.FC<IErrorBoundaryProps> = ({ onRetry }) => {
  return (
    <div className={styles['container']} data-testid="ErrorBoundary">
      <p className={styles['text']}>
        Во время загрузки произошла ошибка. Пожалуйста, проверьте своё интернет соединение и попробуйте ещё раз.
      </p>
      <Button onClick={onRetry}>Повторить</Button>
    </div>
  );
};

ErrorBoundary.displayName = 'ErrorBoundary';
