import loadable from '@loadable/component';
import * as React from 'react';

import { trackModalOpen } from './tracking';
import { LoadableErrorBoundary, LoadableFallback } from '../../../../packages/LoadableHelpers';
import { ModalWindow, HeaderBackable } from '../../../../packages/ModalWindow';
import { useApplicationContext } from '../ApplicationContext';

interface ISearchBlockModalProps {
  open: boolean;
  onClose(): void;
}

const SearchBlockModalContentLoadable = loadable(() => import('../SearchBlockContentContainer'), {
  fallback: <LoadableFallback />,
  ssr: false,
});

export const SearchBlockModalContainer: React.FC<ISearchBlockModalProps> = ({ open, onClose }) => {
  const { logger } = useApplicationContext();
  const modalWindowRef = React.createRef<ModalWindow>();

  React.useEffect(() => {
    if (open) {
      trackModalOpen();
    }
  }, [open]);

  const handleScrollableChange = React.useCallback(() => {
    if (modalWindowRef.current) {
      modalWindowRef.current.scrollToTop();
    }
  }, [modalWindowRef]);

  const handleRetry = React.useCallback(() => {
    SearchBlockModalContentLoadable.load();
  }, []);

  return (
    <ModalWindow
      ref={modalWindowRef}
      header={<HeaderBackable title="Поиск на Циан" onBack={onClose} />}
      open={open}
      onClose={onClose}
      portal={true}
    >
      <LoadableErrorBoundary logger={logger} onRetry={handleRetry}>
        <SearchBlockModalContentLoadable onQueryChange={handleScrollableChange} />
      </LoadableErrorBoundary>
    </ModalWindow>
  );
};
