import { UiKitContextProvider } from '@cian/ui-kit/context';
import { Input } from '@cian/ui-kit/input';
import { ModalWindow } from '@cian/ui-kit/modal';
import memoizeOne from 'memoize-one';
import * as React from 'react';

import * as styles from './GeoLocationModal.css';
import { ContentFallback } from './components/ContentFallback';
import { ErrorBoundary } from './components/ErrorBoundary';
import { LocationLinks, LocationLinksItem } from './components/LocationLinks';
import { NotFound } from './components/NotFound';
import { getFilteredLocations, getPopularLocations, getSortedLocationsByName } from './utils';
import { ELocationsStatus, ILocation, ILocationsState } from '../../types/geoLocation';

export interface IGeoLocationModalProps {
  locations: ILocationsState;
  title: string;
  open?: boolean;
  getLocations(): void;
  setLocation(regionId: number, regionName: string, mainTownRegionId?: number | null): void;
  onClose(): void;
}

export interface IGeoLocationModalState {
  searchValue: string;
}

export class GeoLocationModal extends React.PureComponent<IGeoLocationModalProps, IGeoLocationModalState> {
  public state: IGeoLocationModalState = {
    searchValue: '',
  };

  public componentDidUpdate(prevProps: IGeoLocationModalProps) {
    if (!prevProps.open && this.props.open) {
      const {
        locations: { status },
      } = this.props;

      if (![ELocationsStatus.Loading, ELocationsStatus.Succeed].includes(status)) {
        this.props.getLocations();
      }
    }
  }

  public render() {
    const { title, open, onClose } = this.props;

    return (
      <UiKitContextProvider deviceType="phone" useStaticDeviceType>
        <ModalWindow
          open={open}
          onClose={onClose}
          title={title}
          content={
            <>
              {this.renderSearchInput()}
              <div className={styles['container']}>
                <div className={styles['wrapper']}>{this.renderContent()}</div>
              </div>
            </>
          }
        />
      </UiKitContextProvider>
    );
  }

  private renderSearchInput() {
    const { searchValue } = this.state;
    const {
      locations: { status },
    } = this.props;

    if (status !== ELocationsStatus.Succeed) {
      return null;
    }

    return (
      <div className={styles['input-wrapper']}>
        <Input size="M" value={searchValue} onChange={this.handleInputChange} placeholder="Поиск" />
      </div>
    );
  }

  private renderContent() {
    const {
      locations: { status, items },
      getLocations,
    } = this.props;

    if (status === ELocationsStatus.Failed || (status === ELocationsStatus.Succeed && items.length === 0)) {
      return <ErrorBoundary onRetry={getLocations} />;
    }

    if (status === ELocationsStatus.Loading && items.length === 0) {
      return <ContentFallback />;
    }

    const { searchValue } = this.state;
    const popularLocations = this.popular(items);
    const sortedLocations = this.sorted(items);
    const otherLocations = searchValue ? getFilteredLocations(sortedLocations, searchValue) : sortedLocations;

    if (otherLocations.length === 0) {
      return <NotFound />;
    }

    return (
      <ul className={styles['lists']}>
        {popularLocations.map((list, index) => (
          <li key={`list_${index}`} className={searchValue && styles['hidden']}>
            <LocationLinks>
              {list.map(item => (
                <LocationLinksItem
                  key={`list_${index}_location_${item.id}`}
                  locationId={item.id}
                  onClick={this.handleLinkClick}
                >
                  {item.displayName}
                </LocationLinksItem>
              ))}
            </LocationLinks>
          </li>
        ))}

        <li>
          <LocationLinks>
            {otherLocations.map(item => (
              <LocationLinksItem key={`list_location_${item.id}`} locationId={item.id} onClick={this.handleLinkClick}>
                {item.displayName}
              </LocationLinksItem>
            ))}
          </LocationLinks>
        </li>
      </ul>
    );
  }

  private handleInputChange = (event: React.ChangeEvent, value: string) => {
    this.setState({ searchValue: value });
  };

  private handleLinkClick = (locationId: number, event: React.MouseEvent) => {
    event.preventDefault();

    const {
      locations: { items },
    } = this.props;

    const selectedLocation = items.find(location => location.id === locationId) as ILocation;

    this.props.setLocation(selectedLocation.id, selectedLocation.displayName, selectedLocation.mainTownId);
  };

  private popular = memoizeOne(getPopularLocations);
  private sorted = memoizeOne(getSortedLocationsByName);
}
