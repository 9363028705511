import * as React from 'react';
import { useSelector } from 'react-redux';

import { useRecommendationsEventsSubscribe } from './hooks/useRecommendationsEventsSubscribe';
import { Recommendations } from '../../components/Recommendations';
import { selectCurrentRegion, selectParentRegion } from '../../selectors/regions';
import { ELandingType } from '../../types/landingType';

export const RecommendationsContainer = () => {
  const regionId = useSelector(selectCurrentRegion);
  const parentId = useSelector(selectParentRegion);

  useRecommendationsEventsSubscribe();

  // Обёртка div нужна, чтобы в микрофронт не пробрасывалась пропса data-name, иначе падает с ошибкой
  return (
    <div>
      <Recommendations regionId={parentId ?? regionId} landingType={ELandingType.MainPage} />
    </div>
  );
};
