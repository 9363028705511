import { connect } from 'react-redux';

import { trackRegionClick } from './tracking';
import { getModalTitle } from './utils/getModalTitle';
import { closePopup, EPopupType, getLocations, setLocation } from '../../actions/geoLocation';
import { GeoLocationModal, IGeoLocationModalProps } from '../../components/GeoLocationModal';
import { IApplicationState, TThunkDispatch } from '../../types/redux';

type TGeoLocationModalStateProps = Pick<IGeoLocationModalProps, 'locations' | 'open' | 'title'> & {
  popupType: EPopupType | null;
};
type TGeoLocationModalDispatchProps = Pick<IGeoLocationModalProps, 'getLocations' | 'onClose' | 'setLocation'>;

export function mapStateToProps(state: IApplicationState): TGeoLocationModalStateProps {
  return {
    locations: state.geoLocation.locations,
    title: getModalTitle(state.geoLocation.popup.type),
    popupType: state.geoLocation.popup.type,
    open: state.geoLocation.popup.isOpen,
  };
}

export function mapDispatchToProps(dispatch: TThunkDispatch): TGeoLocationModalDispatchProps {
  return {
    getLocations: () => dispatch(getLocations()),
    setLocation: (regionId: number, regionName: string, mainTownRegionId?: number | null) =>
      dispatch(setLocation(regionId, regionName, mainTownRegionId)),
    onClose: () => dispatch(closePopup()),
  };
}

export function mergeProps(
  stateProps: TGeoLocationModalStateProps,
  dispatchProps: TGeoLocationModalDispatchProps,
): IGeoLocationModalProps {
  const { popupType, ...otherStateProps } = stateProps;
  const { setLocation: dispatchSetLocation, ...otherDispatchProps } = dispatchProps;

  return {
    ...otherStateProps,
    ...otherDispatchProps,
    setLocation: (regionId: number, regionName: string, mainTownRegionId?: number | null) => {
      trackRegionClick(regionName);

      if (popupType === EPopupType.QuickSearch) {
        setTimeout(() => {
          window.location.href = `/nd/search/?deal_type=sale&engine_version=2&offer_type=flat&region=${regionId}`;
        }, 300);

        return;
      }

      dispatchSetLocation(regionId, regionName, mainTownRegionId);
    },
  };
}

export const GeoLocationModalContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(GeoLocationModal);
