/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared';
import {
  TGetLocationModel,
  IGetLocationRequest,
  IMappers,
  IGetLocationResponse,
  IGetLocationResponseError,
  TGetLocationResponse,
} from './types';

const defaultConfig: TGetLocationModel = {
  apiType: 'legacy',
  assertStatusCodes: [200, 400],
  method: 'GET',
  microserviceName: 'monolith-python',
  pathApi: '/cian-api/site/v1/get-location/',
  hostType: 'api',
} as TGetLocationModel;

function createGetLocationModel(parameters: IGetLocationRequest): TGetLocationModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetLocationOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IGetLocationRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchGetLocation<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IGetLocationOptions<TResponse200, TResponse400>): Promise<TResponse200 | TResponse400 | TGetLocationResponse> {
  const { response, statusCode } = await httpApi.fetch(createGetLocationModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetLocationResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IGetLocationResponseError);
    }
  }

  return { response, statusCode } as TGetLocationResponse;
}

export { defaultConfig, createGetLocationModel, fetchGetLocation };
