import cn from 'classnames';
import * as React from 'react';

import * as styles from './Section.css';

type TSectionIndent = 'S' | 'M' | 'L';

const INDENT_TO_CLASS: { [key in TSectionIndent]: string } = {
  S: styles['small-indent'],
  M: styles['middle-indent'],
  L: styles['large-indent'],
};

export interface ISectionProps {
  id?: string;
  title?: string;
  indent?: TSectionIndent;
  children?: React.ReactNode;
}

const SectionComponent: React.FC<ISectionProps> = ({ id, title, children, indent }) => {
  const classNames = cn(styles['container'], indent && INDENT_TO_CLASS[indent]);

  return (
    <section className={classNames} id={id}>
      {title && <h2 className={styles['title']}>{title}</h2>}
      {children}
    </section>
  );
};

export const Section = React.memo<React.PropsWithChildren<ISectionProps>>(SectionComponent);
