import * as React from 'react';
import { useSelector } from 'react-redux';

import { trackAddAdvClick } from './analytics';
import { useGetButtonProps } from './hooks/useGetButtonProps';
import { AddOfferButton } from '../../components/AddOfferButton';
import { Section } from '../../components/Section';
import { selectAddFormLink } from '../../selectors/addFormLink';
import { useApplicationContext } from '../ApplicationContext';

export function AddOfferButtonContainer() {
  const { config } = useApplicationContext();
  const addFormLink = useSelector(selectAddFormLink);
  // TODO: Безжалостно выпилить как только завершится эксперимент SPEC-348
  const { text, url } = useGetButtonProps();

  if (!config.get('mobileMainpage.addOfferButtonEnabled')) {
    return null;
  }

  return (
    <Section indent="M">
      <AddOfferButton text={text} addFormLink={url || addFormLink} onClick={trackAddAdvClick} />
    </Section>
  );
}
