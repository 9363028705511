import { TDevice } from '../../../types/device';

import type { TDeviceType } from '@cian/ui-kit/types';

export function normalizeDeviceType(deviceType: TDevice): TDeviceType {
  if (deviceType === 'mobile') {
    return 'phone';
  }

  return deviceType;
}
