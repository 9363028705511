import * as React from 'react';
import { useSelector } from 'react-redux';

import { trackRegionSelect } from './analytics';
import { Section } from '../../components/Section';
import { ISeoRegion, SeoRegions } from '../../components/SeoRegions';
import { IApplicationState } from '../../types/redux';

export const selector = (state: IApplicationState): ISeoRegion[] =>
  state.regions.items.map(item => ({ title: item.title, href: item.url }));

export const SeoRegionsContainer: React.FC = () => {
  const items = useSelector(selector);

  if (items.length === 0) {
    return null;
  }

  return (
    <Section title="Объявления по всей России" indent="L">
      <SeoRegions items={items} onRegionClick={trackRegionSelect} />
    </Section>
  );
};
