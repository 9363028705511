import { ILocation } from '../../types/geoLocation';
import { ITypedReduxAction } from '../../types/redux/actionType';

export enum EPopupActionType {
  Open = 'geoLocation/popup/open',
  Close = 'geoLocation/popup/close',
}

export enum EPopupType {
  GeoChanger = 'geoChanger',
  QuickSearch = 'quickSearch',
}

export type TPopupOpen = ITypedReduxAction<EPopupActionType.Open, EPopupType>;
export type TPopupClose = ITypedReduxAction<EPopupActionType.Close>;

export enum EGetLocationsActionType {
  Loading = 'geoLocation/getLocations/loading',
  Succeed = 'geoLocation/getLocations/succeed',
  Failed = 'geoLocation/getLocations/failed',
}

export type TGetLocationsLoading = ITypedReduxAction<EGetLocationsActionType.Loading>;
export type TGetLocationsSucceed = ITypedReduxAction<EGetLocationsActionType.Succeed, ILocation[]>;
export type TGetLocationsFailed = ITypedReduxAction<EGetLocationsActionType.Failed>;

export type TGeoLocationActions =
  | TPopupOpen
  | TPopupClose
  | TGetLocationsLoading
  | TGetLocationsSucceed
  | TGetLocationsFailed;
