import * as React from 'react';

import { Container } from '../../components/Container';
import { Section } from '../../components/Section';
import { AddOfferButtonContainer } from '../AddOfferButtonContainer';
import { AdfoxBrandBannerContainer } from '../AdfoxBrandBannerContainer';
import { GeoLocationModalContainer } from '../GeoLocationModalContainer';
import { PikPromoBannerContainer } from '../PikPromoBannerContainer';
import { QuickFiltersContainer } from '../QuickFiltersContainer';
import { QuickLinksContainer } from '../QuickLinksContainer';
import { RecommendationsContainer } from '../RecommendationsContainer';
import { RegionSelectCallContainer } from '../RegionSelectCallContainer';
import { SearchBlockContainer } from '../SearchBlockContainer';
import { SeoCrossLinksContainer } from '../SeoCrossLinksContainer';
import { SeoDescriptionContainer } from '../SeoDescriptionContainer';
import { SeoRegionsContainer } from '../SeoRegionsContainer';
import { ServicesContainer } from '../ServicesContainer';

export const Page: React.FC = () => {
  return (
    <Container>
      <SearchBlockContainer />

      <Section indent="S">
        <QuickFiltersContainer />
      </Section>

      <AddOfferButtonContainer />

      <Section indent="M">
        <ServicesContainer />
      </Section>

      <AdfoxBrandBannerContainer />

      <Section indent="M">
        <RecommendationsContainer />
      </Section>

      <PikPromoBannerContainer />

      <QuickLinksContainer />

      <SeoRegionsContainer />

      <SeoDescriptionContainer />

      <SeoCrossLinksContainer />

      <RegionSelectCallContainer />

      <GeoLocationModalContainer />
    </Container>
  );
};
