// TODO: Безжалостно выпилить как только завершится эксперимент SPEC-348
import { useSelector } from 'react-redux';

import { EUserType } from '../../../../node/repositories/homeowners/entities/schemas/GetUserTypeResponseSchema';
import { selectExperiments } from '../../../selectors/experiments';
import { selectUserType } from '../../../selectors/user/selectUserType';
import { getExperimentGroup } from '../../../utils/experiments';
import {
  CONTROL_GROUP_TEXT_NODE,
  CONTROL_GROUP_URL_NODE,
  EXPERIMENTAL_GROUP_NAME_NODE,
  EXPERIMENTAL_GROUP_TEXT_NODE,
  EXPERIMENTAL_GROUP_URL_NODE,
  EXPERIMENT_NAME_NODE,
} from '../../../utils/placementTextExperimentConstants';
import { useApplicationContext } from '../../ApplicationContext';

export const useGetButtonProps = () => {
  const { config } = useApplicationContext();
  const userType = useSelector(selectUserType);
  const experiments = useSelector(selectExperiments);
  const experimentName = config.get<string>(EXPERIMENT_NAME_NODE);
  const experimentalGroupName = config.get<string>(EXPERIMENTAL_GROUP_NAME_NODE);

  const experimentalProps = {
    text: config.get<string>(EXPERIMENTAL_GROUP_TEXT_NODE),
    url: config.get<string>(EXPERIMENTAL_GROUP_URL_NODE),
  };

  const controlProps = {
    text: config.get<string>(CONTROL_GROUP_TEXT_NODE),
    url: config.get<string>(CONTROL_GROUP_URL_NODE),
  };

  if (userType === EUserType.Specialist || !experimentName || !experimentalGroupName) {
    return {
      text: null,
      url: null,
    };
  }

  const experimentGroup = getExperimentGroup(experiments, experimentName);

  return experimentGroup === experimentalGroupName ? experimentalProps : controlProps;
};
