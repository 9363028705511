import { TDevice } from '../types/device';

interface ICreatePage {
  deviceType: TDevice;
  extra: {
    [key: string]: string;
  };
}

export function createCianAnalyticsPageWithExtra({ extra, deviceType }: ICreatePage) {
  return {
    pageType: 'Home',
    siteType: deviceType,
    extra,
  };
}
