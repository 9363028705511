import { Button } from '@cian/ui-kit';
import { IconFunctionalPlusCircle16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

export interface IAddOfferButtonProps {
  // TODO: Безжалостно выпилить как только завершится эксперимент SPEC-348
  text: string | null;
  addFormLink: string;
  onClick(): void;
}

export function AddOfferButton({ text, onClick, addFormLink }: IAddOfferButtonProps) {
  const onButtonClick = React.useCallback(() => {
    onClick();
    window.open(addFormLink);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addFormLink]);

  return (
    <Button
      beforeIcon={<IconFunctionalPlusCircle16 color="icon-main-default" />}
      onClick={onButtonClick}
      size="L"
      fullWidth
      theme="fill_secondary"
      data-testid="add-offer-button"
    >
      {text || 'Разместить объявление'}
    </Button>
  );
}
