import { IconActionSearch16 } from '@cian/ui-kit-design-tokens/icons';
import * as classNames from 'classnames';
import * as React from 'react';

import * as styles from './SearchBlock.css';

interface ISearchBlockProps {
  text?: string;
  placeholder?: string;
  onSearchClick(event: React.MouseEvent<HTMLAnchorElement>): void;
}

export const SearchBlock: React.FC<ISearchBlockProps> = ({
  onSearchClick,
  text,
  placeholder = 'Например, купить квартиру в Москве',
}) => {
  return (
    <div className={styles['container']}>
      <a
        href="/nd/search/"
        className={classNames(styles['button'], styles['geo-button'], !text && styles['placeholder'])}
        onClick={onSearchClick}
      >
        <span className={styles['icon']}>
          <IconActionSearch16 />
        </span>
        <span className={styles['text']}>{text || placeholder}</span>
      </a>
    </div>
  );
};
