import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectCurrentRegion, selectParentRegion } from '../../../selectors/regions';

export function usePuids() {
  const currentRegion = useSelector(selectCurrentRegion);
  const parentRegion = useSelector(selectParentRegion);

  return useMemo(
    () => ({
      puid5: `obl_id_${parentRegion || currentRegion}`,
    }),
    [currentRegion, parentRegion],
  );
}
