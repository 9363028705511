import * as React from 'react';
import { useSelector } from 'react-redux';

import { Section } from '../../components/Section';
import { selectNbMobwebBrandShortcuttsRedesignV2Enabled } from '../../selectors/experiments/brandBannerAndQuickLinksExperiment';
import { AdfoxBannerContainer, IAdfoxBannerContainerProps } from '../AdfoxBannerContainer';

const ADFOX_PARAMETERS: IAdfoxBannerContainerProps['pParams'] = { p1: 'dfdrs', p2: 'fjjq' };

const ANALYTICS_DATA: IAdfoxBannerContainerProps['postViewAnalyticsData'] = {
  place: 'recs',
  place__DEPRECATED: 'recs',
  name__DEPRECATED: 'Home_recs_brand_banner',
  extra__DEPRECATED: {
    offerType: 'Brand_banner',
  },
};

const SIZE: IAdfoxBannerContainerProps['size'] = { width: '100%', height: '62px' };

export function AdfoxBrandBannerContainer() {
  const isEnabled = useSelector(selectNbMobwebBrandShortcuttsRedesignV2Enabled);

  if (!isEnabled) {
    return null;
  }

  return (
    <Section indent="S">
      <AdfoxBannerContainer pParams={ADFOX_PARAMETERS} size={SIZE} postViewAnalyticsData={ANALYTICS_DATA} />
    </Section>
  );
}
