import * as Cookies from 'js-cookie';

export function setLocationCookies(regionId: number, regionName: string, mainTownRegionId?: number | null) {
  Cookies.set('forever_region_id', String(regionId), { domain: '.cian.ru', expires: 365 });
  Cookies.set('session_region_id', String(regionId), { domain: '.cian.ru' });

  if (mainTownRegionId) {
    Cookies.set('forever_main_town_region_id', String(mainTownRegionId), { domain: '.cian.ru', expires: 365 });
    Cookies.set('session_main_town_region_id', String(mainTownRegionId), { domain: '.cian.ru' });
  } else {
    Cookies.remove('forever_main_town_region_id', { domain: '.cian.ru' });
    Cookies.remove('session_main_town_region_id', { domain: '.cian.ru' });
  }

  Cookies.set('forever_region_name', regionName, { domain: '.cian.ru', expires: 365 });
  Cookies.set('session_region_name', regionName, { domain: '.cian.ru' });
}
