import { IconActionArrowUp16 } from '@cian/ui-kit-design-tokens/icons';
import cx from 'classnames';
import * as React from 'react';

import * as styles from './SeoCrossLinksGroup.css';
import { capitalize } from './utis/capitalize';
import { ILink } from '../../types/crossLinks';

interface ISeoCrossLinksGroupProps {
  label: string;
  links: ILink[];
  id: string;
}

export function SeoCrossLinksGroup({ label, links, id }: ISeoCrossLinksGroupProps) {
  const [expanded, setExpanded] = React.useState<boolean>(false);

  const headingId = `${id}_heading`;
  const bodyId = `${id}_body`;

  const toggleExpandedState = () => {
    setExpanded(!expanded);
  };

  return (
    <div className={cx(styles['container'], expanded && styles['container_expanded'])} data-name="SeoCrossLinksGroup">
      <div
        id={headingId}
        data-name={headingId}
        role="button"
        aria-expanded={expanded}
        aria-controls={bodyId}
        tabIndex={0}
        className={styles['title']}
        onClick={toggleExpandedState}
      >
        <div className={styles['heading']}>{capitalize(label)}</div>
        <div className={styles['arrow-wrapper']}>
          <IconActionArrowUp16 />
        </div>
      </div>

      <ul className={styles['list']} id={bodyId} data-name={bodyId} aria-describedby={headingId}>
        {links.map((link, index) => (
          <li key={`${id}-link-${index}`}>
            <a className={styles['link']} href={link.url}>
              {capitalize(link.anchor)}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}
