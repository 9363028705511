import { ensureMicrofrontend } from './internal';
import { createApplicationContext } from '../applicationContext';
import { createApplicationState } from '../applicationState';
import { renderApplication } from '../renderApplication';

export async function runApp() {
  const context = createApplicationContext();
  const store = createApplicationState(context);

  const microfrontend = await ensureMicrofrontend();

  renderApplication({ store, context, microfrontend });
}
