import { IQuickLinks } from '../../types/quickLinks';
import { TReduxActions } from '../../types/redux';

export const defaultState: IQuickLinks = {
  items: [],
};

export function quickLinksReducer(state: IQuickLinks = defaultState, action: TReduxActions): IQuickLinks {
  switch (action.type) {
    default:
      return state;
  }
}
