import { IModalFullscreenProps, ModalFullscreen } from '@cian/ui-kit/modal';
import * as classNames from 'classnames';
import * as React from 'react';

import * as styles from './ModalWindow.css';

export interface IModalWindowProps extends Omit<IModalFullscreenProps, 'theme'> {
  header?: React.ReactChild;
  footer?: React.ReactChild;
  fixed?: boolean;
}

export class ModalWindow extends React.Component<IModalWindowProps> {
  private containerRef = React.createRef<HTMLDivElement>();

  public render() {
    const { header, children, footer, fixed, ...sharedProps } = this.props;

    return (
      <ModalFullscreen {...sharedProps}>
        <div ref={this.containerRef} className={classNames(styles['container'], fixed && styles['container-fixed'])}>
          {header}
          <div className={styles['content']}>{children}</div>
          {footer}
        </div>
      </ModalFullscreen>
    );
  }

  public scrollToTop() {
    if (this.containerRef.current) {
      this.containerRef.current.scrollTo(0, 0);
    }
  }
}
