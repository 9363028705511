import { getSizeProps, TAdFoxBannerTemplate } from '@cian/adfox-component';
import { Base64 } from 'js-base64';
import * as React from 'react';
import { debounce } from 'throttle-debounce';

import { BrandBanner } from '../../../components/Adfox';
import { TMainpageBrandContext } from '../../../types/adfox';
import { useApplicationContext } from '../../ApplicationContext';

const OWN_BUNDLE_NAMES = ['banner.transfer'];
const OWN_TYPES: TAdFoxTemplatesParams['type'][] = ['mainpageBrand'];

export type TAdFoxTemplatesParams = TMainpageBrandContext;

export const OwnTemplates: React.FC<TAdFoxBannerTemplate> = props => {
  const { bundleName, bundleParams, containerId } = props;

  const { logger } = useApplicationContext();

  const debouncedCallback = React.useMemo(() => debounce<(fn: VoidFunction) => void>(500, fn => fn()), []);

  if (bundleName && OWN_BUNDLE_NAMES.includes(bundleName) && bundleParams) {
    if (bundleName === 'banner.transfer' && bundleParams.htmlBase64) {
      try {
        const decodedParams = Base64.decode(bundleParams.htmlBase64);

        let parsedDecodedParams: object;

        // Пытаемся парсить json, если не получается, то заменяем все новые строки на пробелы и пытаемся парсить еще раз
        try {
          parsedDecodedParams = JSON.parse(decodedParams);
        } catch (e) {
          parsedDecodedParams = JSON.parse(decodedParams.replace(/[\r\n]+/gm, ' '));

          debouncedCallback(() =>
            logger.error(e, {
              message: '[AdFox] Failed to parse bundleParams',
              details:
                'Была ошибка в json, ошибка состояла в том, что использовалась новая строка "\n", нужно смотреть РК и править, что бы не присылали битый json. Эту ошибку обработали на клиенте',
              bundleParams: JSON.stringify(bundleParams),
            }),
          );
        }

        if (isParsedParamsValid(parsedDecodedParams)) {
          if (OWN_TYPES.includes(parsedDecodedParams.type)) {
            switch (parsedDecodedParams.type) {
              case 'mainpageBrand':
                return <BrandBanner context={parsedDecodedParams} />;
            }
          }
        }
      } catch (e) {
        debouncedCallback(() =>
          logger.error(e, {
            message: '[AdFox] Failed to decode/parse bundleParams',
            details: 'Баннер скрыли, не будем показывать, так как иначе выведется только битый текст json типа',
            bundleParams: JSON.stringify(bundleParams),
          }),
        );

        return null;
      }
    }
  }

  return <div id={containerId} style={getSizeProps(props)} data-name="OwnTemplates" />;
};

function isParsedParamsValid(params: object): params is TAdFoxTemplatesParams {
  return 'type' in params;
}
