import { useBooleanState } from '@cian/react-utils';
import * as React from 'react';

import { SearchBlock } from '../../components/SearchBlock';
import { useApplicationContext } from '../ApplicationContext';
import { SearchBlockModalContainer } from '../SearchBlockModalContainer';

export const SearchBlockContainer: React.FC = () => {
  const context = useApplicationContext();
  const { state: isModalOpen, setTrue: setModalOpen, setFalse: setModalClose } = useBooleanState(false);
  const isSearchEnabled = context.config.get<boolean>('mobile-mainpage-frontend.textSearch.Enabled');

  const handleSearchButtonClick = React.useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      setModalOpen();
    },
    [setModalOpen],
  );

  if (!isSearchEnabled) {
    return null;
  }

  return (
    <>
      <SearchBlock onSearchClick={handleSearchButtonClick} />
      <SearchBlockModalContainer open={isModalOpen} onClose={setModalClose} />
    </>
  );
};

SearchBlockContainer.displayName = 'SearchBlockContainer';
