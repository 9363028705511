export enum ELocationsStatus {
  Initial = 'initial',
  Loading = 'loading',
  Succeed = 'succeed',
  Failed = 'failed',
}

export interface ILocation {
  /** ID объекта **/
  id: number;
  /** ID столицы, если объект - регион **/
  mainTownId?: number | null;
  /** Название для отображения **/
  displayName: string;
}

export interface ILocationsState {
  status: ELocationsStatus;
  items: ILocation[];
}
