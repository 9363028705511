import { ILogger } from '@cian/logger';
import { Button } from '@cian/ui-kit/button';
import * as React from 'react';

import * as styles from './LoadableErrorBoundary.css';

interface ILoadableErrorBoundaryProps {
  logger: ILogger;
  onRetry(): void;
}

interface ILoadableErrorBoundaryState {
  hasError: boolean;
}

export class LoadableErrorBoundary extends React.Component<
  React.PropsWithChildren<ILoadableErrorBoundaryProps>,
  ILoadableErrorBoundaryState
> {
  public state: ILoadableErrorBoundaryState = {
    hasError: false,
  };

  public static getDerivedStateFromError(): ILoadableErrorBoundaryState {
    return {
      hasError: true,
    };
  }

  public componentDidCatch(error: Error, info: React.ErrorInfo) {
    this.props.logger.error(error, { ...info });
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div className={styles['container']}>
          <p className={styles['text']}>
            Во время загрузки произошла ошибка. Пожалуйста, проверьте своё интернет соединение и попробуйте ещё раз.
          </p>
          <Button theme="fill_primary" onClick={this.handleRetryClick}>
            Повторить
          </Button>
        </div>
      );
    }

    return this.props.children;
  }

  private handleRetryClick = () => {
    this.props.onRetry();

    this.setState({
      hasError: false,
    });
  };
}
