import { makeContextModule } from '@cian/react-utils';

import { IApplicationContext } from '../../types/application';

export const {
  Context: ApplicationContext,
  useContext: useApplicationContext,
  withContext: withApplicationContext,
} = makeContextModule<IApplicationContext>({
  contextName: 'ApplicationContext',
});
