import { IconActionChevronRight12 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import * as styles from './SeoRegion.css';

export interface ISeoRegionProps {
  title: string;
  href: string;
  onClick?(): void;
}

const SeoRegionComponent: React.FC<ISeoRegionProps> = ({ title, href, onClick }) => {
  return (
    <li className={styles['container']}>
      <a href={href} onClick={onClick}>
        {title}
        <IconActionChevronRight12 />
      </a>
    </li>
  );
};

export const SeoRegion = React.memo(SeoRegionComponent);
