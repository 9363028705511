import { isExperimentActive, selectExperiments } from './experiments';
import { NB_MOBWEB_BRAND_SHORTCUTS_REDESIGN_V2 } from '../../constants/experiments';
import { Regions } from '../../constants/region';
import { IApplicationState } from '../../types/redux';

export const selectNbMobwebBrandShortcuttsRedesignV2Enabled = (state: IApplicationState): boolean => {
  const experiments = selectExperiments(state);

  const bGroup = isExperimentActive(experiments, NB_MOBWEB_BRAND_SHORTCUTS_REDESIGN_V2, 'B');

  return (
    bGroup &&
    [Regions.Moscow, Regions.MoscowAndRegion, Regions.MoscowRegion].includes(state.geoLocation.currentRegion.id)
  );
};
