export enum ELandingType {
  /** Снять — квартиру на долгий срок **/
  'FlatRent' = 'flatRent',
  /** Посуточно — снять квартиру посуточно **/
  'FlatRentForDay' = 'flatRentForDay',
  /** Купить — квартиру во вторичке **/
  'SecondaryFlatSale' = 'secondaryFlatSale',
  /** Загородка — купить дом, дачу, таунхаус, участок **/
  'SuburbanSale' = 'suburbanSale',
  /** Главная cian.ru **/
  'MainPage' = 'mainPage',
}
