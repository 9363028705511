import * as React from 'react';
import { useSelector } from 'react-redux';

import { SeoCrossLinks } from '../../components/SeoCrossLinks';
import { selectSeoCrossLinks } from '../../selectors/seoCrossLinks';
import { useApplicationContext } from '../ApplicationContext';

export const SeoCrossLinksContainer: React.FC = () => {
  const context = useApplicationContext();

  const crossLinks = useSelector(selectSeoCrossLinks);
  const isCrossLinksEnabled = context.config.get<boolean>('mobileMainpage.seoCrossLinks.Enabled') || false;

  if (!isCrossLinksEnabled || !crossLinks || !crossLinks.length) {
    return null;
  }

  return <SeoCrossLinks items={crossLinks} />;
};
