import { INewOfferForm } from '../../types/newOfferForm';
import { TReduxActions } from '../../types/redux';

export const defaultState = {
  url: '/razmestit-obyavlenie/',
};

export function newOfferFormReducer(state: INewOfferForm = defaultState, action: TReduxActions): INewOfferForm {
  switch (action.type) {
    default:
      return state;
  }
}
