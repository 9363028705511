import * as classnames from 'classnames';
import * as React from 'react';

import * as styles from './CollapsedButton.css';

interface ICollapsedButtonProps {
  isOpen: boolean;
  onClick(): void;
}

export const CollapsedButton = ({ isOpen, onClick }: ICollapsedButtonProps) => {
  const toggleClasses = classnames(styles['toggle-btn'], isOpen && styles['toggle-btn--close']);

  return (
    <div className={toggleClasses} onClick={onClick} data-testid="CollapsedButton">
      {isOpen ? 'Свернуть' : 'Показать полностью'}
    </div>
  );
};
