import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setLocationCookies } from '../../../browser/utils/location';
import { EPopupType, openPopup } from '../../actions/geoLocation';
import { RegionSelectCall } from '../../components/RegionSelectCall';
import { IApplicationState, TThunkDispatch } from '../../types/redux';

function mapStateToProps(state: IApplicationState) {
  return {
    region: state.geoLocation.currentRegion,
    isRegionSelected: state.geoLocation.isRegionSelected,
  };
}

export function RegionSelectCallContainer() {
  const { isRegionSelected, region } = useSelector(mapStateToProps);
  const dispatch = useDispatch<TThunkDispatch>();
  const [opened, setOpened] = React.useState(!isRegionSelected);

  const close = React.useCallback(() => {
    setOpened(false);
  }, []);
  const onAgree = React.useCallback(() => {
    setLocationCookies(region.id, region.name, region.mainTownId);
    close();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onRegionChange = React.useCallback(() => {
    dispatch(openPopup(EPopupType.GeoChanger));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!opened) {
    return null;
  }

  return (
    <RegionSelectCall regionName={region.name} onClose={close} onAgree={onAgree} onRegionChange={onRegionChange} />
  );
}
