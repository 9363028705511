import { Button } from '@cian/ui-kit/button';
import { IconChevronLeft24 } from '@cian/ui-kit/icons';
import * as React from 'react';

import * as styles from './HeaderBackable.css';
import { Header, IHeaderProps } from '../Header';

interface IHeaderBackableProps extends Omit<IHeaderProps, 'left'> {
  onBack(): void;
}

export const HeaderBackable: React.FC<IHeaderBackableProps> = ({ onBack, ...sharedProps }) => {
  return (
    <Header
      {...sharedProps}
      left={
        <span className={styles['button']}>
          <Button
            theme="fill_white_primary"
            size="XS"
            beforeIcon={<IconChevronLeft24 display="block" />}
            onClick={onBack}
          />
        </span>
      }
    />
  );
};
