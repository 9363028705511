import { getExperiment } from './getExperiment';
import { IExperiment } from '../../types/experiments';

export function getExperimentGroup(experiments: IExperiment[], experimentName: string): string | null {
  const experiment = getExperiment(experiments, experimentName);

  if (!experiment || !experiment.groupName) {
    return null;
  }

  return experiment.groupName;
}
