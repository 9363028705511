import { ca } from '@cian/analytics';

export function trackRegionClick(region: string) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'main_page',
    action: 'GeoSuggest',
    label: `Select/region_${region}`,
  });
}
