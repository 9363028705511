import * as React from 'react';
import { useSelector } from 'react-redux';

import { getTrackPromoBanner } from './tracking';
import { PikPromoBanner } from '../../components/PikPromoBanner';
import { PIK_LANDING_QS } from '../../constants/common';
import { getDeviceType } from '../../selectors/page';
import { selectPikPromoBanner } from '../../selectors/pikPromoBanner';
import { selectCurrentRegion } from '../../selectors/regions';
import { IApplicationState } from '../../types/redux';
import { useApplicationContext } from '../ApplicationContext';

function selectParentRegion(state: IApplicationState) {
  return state.geoLocation.currentRegion.parentId;
}

export function PikPromoBannerContainer() {
  const { config } = useApplicationContext();
  const currentRegionId = useSelector(selectCurrentRegion);
  const parentRegionId = useSelector(selectParentRegion);
  const pikPromoBanner = useSelector(selectPikPromoBanner);
  const regionId = parentRegionId || currentRegionId;
  const deviceType = useSelector(getDeviceType);
  const pikRegions = config.get<number[]>('PikPromoRegions') || [];

  if (!pikRegions.includes(regionId)) {
    return null;
  }

  return (
    <PikPromoBanner
      onClick={getTrackPromoBanner({ label: 'pik', deviceType, queryString: PIK_LANDING_QS })}
      disclaimer={pikPromoBanner.disclaimer}
      hitUrl={pikPromoBanner.hitUrl}
    />
  );
}
