import { getConfig } from '@cian/config/browser';
import { getHttpApi } from '@cian/http-api/browser';
import { getLogger } from '@cian/logger/browser';
import { getTelemetry } from '@cian/telemetry/browser';

import { ICustomContextParams } from '../../../shared/types/ICustomContextParams';
import { IApplicationContext } from '../../../shared/types/application';

export function createApplicationContext(): IApplicationContext {
  const config = getConfig();

  return {
    config,
    logger: getLogger(),
    httpApi: getHttpApi(),
    telemetry: getTelemetry(),
    custom: config.getStrict<ICustomContextParams>('applicationContext.custom'),
  };
}
