import { ISearchCatalogItemSchema } from '../repositories/monolith-python/entities/schemas/SearchCatalogItemSchema';
import { ILocation } from '../types/geoLocation/locations';

export const prepareLocations = (locationsData: ISearchCatalogItemSchema[]): ILocation[] => {
  return locationsData.map(location => ({
    id: location.id,
    mainTownId: location.mainTownId,
    displayName: location.displayName,
  }));
};
