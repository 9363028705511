import posytochno from './assets/calendar.png';
import commercial from './assets/commercial.png';
import flatRent from './assets/flat_rent.png';
import flatSale from './assets/flat_sale.png';
import mortgage from './assets/mortgage.svg';
import newbuilding from './assets/newbuilding.png';
import suburban from './assets/suburban.png';
import suburbanBuildersProjects from './assets/suburban_builders_projects.svg';
import { IQuickFiltersBlock } from '../../types/quickFilters';

export const iconsMap: { [key: string]: string } = {
  flat_sale: flatSale,
  flat_rent: flatRent,
  suburban,
  commercial,
  mortgage,
  newbuilding,
  posytochno,
  suburban_builders_projects: suburbanBuildersProjects,
};

export function getQuickFiltersIconByKey(key: IQuickFiltersBlock['key']): string {
  return iconsMap[key];
}
