import { Button } from '@cian/ui-kit/button';
import { IconActionClose16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import * as styles from './RegionSelectCall.css';

export interface IRegionSelectCallProps {
  regionName: string;
  onClose(): void;
  onAgree(): void;
  onRegionChange(): void;
}

export const RegionSelectCall: React.FC<IRegionSelectCallProps> = ({
  regionName,
  onClose,
  onAgree,
  onRegionChange,
}) => {
  return (
    <div className={styles['container']}>
      <div className={styles['close-icon']} onClick={onClose} data-testid="CloseIcon">
        <IconActionClose16 display="block" />
      </div>
      <div className={styles['text']}>
        Ваш регион для поиска
        <br />
        <strong>{regionName}</strong>?
      </div>
      <div className={styles['buttons']}>
        <Button onClick={onAgree}>Да</Button>
        <Button theme="fill_secondary" onClick={onRegionChange}>
          Выбрать регион
        </Button>
      </div>
    </div>
  );
};
