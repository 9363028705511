import { ELocationsStatus, IGeoLocationState } from '../../types/geoLocation';

export const createDefaultGeoLocation = (): IGeoLocationState => {
  return {
    isRegionSelected: false,
    popup: {
      type: null,
      isOpen: false,
    },
    locations: {
      status: ELocationsStatus.Initial,
      items: [],
    },
    currentRegion: { id: 0, name: '' },
  };
};
