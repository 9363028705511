// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */

export interface IGetUserTypeResponseSchema {
  /** Количество доступных для публикации объявлений как собственник **/
  announcementsPublishCount: number;
  /** Количество опубликованных объявлений **/
  announcementsPublishedCount: number;
  /** Может ли опубликовать объявление как собственник **/
  canPublishAsHomeowner: boolean;
  /** Тип пользователя **/
  userType: EUserType;
}
export enum EUserType {
  /** Собственник **/
  'Homeowner' = 'homeowner',
  /** Специалист **/
  'Specialist' = 'specialist',
  /** Неопределенный **/
  'Undefined' = 'undefined',
}
