import * as React from 'react';

import * as styles from './LocationLinksItem.css';

export interface ILocationLinksItemProps {
  href?: string;
  locationId: number;
  children?: React.ReactNode;
  onClick?(locationId: number, event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void;
}

export const LocationLinksItem: React.FC<ILocationLinksItemProps> = React.memo((props: ILocationLinksItemProps) => {
  const { href = '#', locationId, children, onClick } = props;

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (onClick) {
      event.preventDefault();
      onClick(locationId, event);
    }
  };

  return (
    <li className={styles['container']}>
      <a href={href} className={styles['link']} onClick={handleClick}>
        {children}
      </a>
    </li>
  );
});

LocationLinksItem.displayName = 'LocationLinksItem';
