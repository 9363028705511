import { EPopupType } from '../../../actions/geoLocation';

export function getModalTitle(type: EPopupType | null) {
  let title = '';
  switch (type) {
    case EPopupType.GeoChanger:
      title = 'Выбрать регион';
      break;
    case EPopupType.QuickSearch:
      title = 'Где будем искать?';
      break;
  }

  return title;
}
