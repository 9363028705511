import { setLocationCookies } from '../../../browser/utils/location';
import { ResponseError } from '../../errors/responseError';
import { fetchGetLocation, TGetLocationResponse } from '../../repositories/monolith-python/v1/get-location';
import { TThunkAction } from '../../types/redux/thunk';

export function setLocation(
  regionId: number,
  regionName: string,
  mainTownRegionId?: number | null,
): TThunkAction<Promise<void>> {
  return async (_, getState, { httpApi, logger }) => {
    const { subdomain } = getState();

    setLocationCookies(regionId, regionName, mainTownRegionId);

    try {
      const locationData: TGetLocationResponse = await fetchGetLocation({
        httpApi,
        parameters: {
          regionId,
        },
        config: {
          subdomain,
        },
      });

      if (locationData.statusCode !== 200) {
        const error = new ResponseError({
          domain: 'geoLocation#setLocation',
          message: locationData.response.message,
          details: {
            error: locationData.response.errors,
          },
        });

        logger.error(error);

        window.location.reload();

        throw error;
      }

      const {
        response: {
          data: { baseHost },
        },
      } = locationData;
      if (!baseHost) {
        const error = new ResponseError({
          domain: 'geoLocation#setLocation',
          message: 'Unexpectedly no base host',
        });

        logger.error(error);

        window.location.reload();

        throw error;
      }

      window.location.href = baseHost;

      return;
    } catch (ex) {
      const error = new ResponseError({
        domain: 'geoLocation#setLocation',
        message: ex.message,
      });

      logger.error(error);

      window.location.reload();

      throw error;
    }
  };
}
