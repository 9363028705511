import * as React from 'react';

import * as styles from './NotFound.css';

export const NotFound: React.FC = () => {
  return (
    <div className={styles['container']} data-testid="NotFound">
      <p className={styles['title']}>Ничего не найдено</p>
      <p className={styles['text']}>Попробуйте изменить поиск</p>
    </div>
  );
};

NotFound.displayName = 'NotFound';
