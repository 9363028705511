/* eslint-disable */

//tslint:disable

import { IModel, IModelResponse } from '@cian/http-api/shared/model';
import { ISearchRegionsCitiesResponseSchema } from '../../entities/schemas/SearchRegionsCitiesResponseSchema';

export type TSearchRegionsCitiesModel = IModel<ISearchRegionsCitiesRequest, TSearchRegionsCitiesResponse>;

export interface ISearchRegionsCitiesRequest {
  /** Строка поиска геообъектов по названию **/
  text?: string | null;
}

export type TSearchRegionsCitiesResponse = ISearchRegionsCitiesResponse200 | ISearchRegionsCitiesResponse400;

export interface ISearchRegionsCitiesResponse200 extends IModelResponse<ISearchRegionsCitiesResponse> {
  statusCode: 200;
}

export interface ISearchRegionsCitiesResponse400 extends IModelResponse<ISearchRegionsCitiesResponseError> {
  statusCode: 400;
}

export interface ISearchRegionsCitiesResponse {
  data: ISearchRegionsCitiesResponseSchema;
  status: EStatus;
}

export interface ISearchRegionsCitiesResponseError {
  errors: any[];
  message: string;
}

export type TResponses = ISearchRegionsCitiesResponse | ISearchRegionsCitiesResponseError;

export enum EStatus {
  /** undefined **/
  Ok = 'ok',
}

export interface IMappers<TResponse200, TResponse400> {
  200(response: ISearchRegionsCitiesResponse): TResponse200;
  400(response: ISearchRegionsCitiesResponseError): TResponse400;
}
