import { PIK_LANDING_PATH } from '../../constants/common';
import { IPikPromoBanner } from '../../types/pikPromoBanner';

export const defaultState = {
  hitUrl: PIK_LANDING_PATH,
  disclaimer: '',
};

export function pikPromoBannerReducer(state: IPikPromoBanner = defaultState): IPikPromoBanner {
  return state;
}
