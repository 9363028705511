import { TReduxActions } from '../../types/redux';
import { ISeo } from '../../types/seo';

export const defaultState = {
  h1: '',
  description: '',
  keywords: '',
  title: '',
  text: '',
};

export function seoReducer(state: ISeo = defaultState, action: TReduxActions): ISeo {
  switch (action.type) {
    default:
      return state;
  }
}
