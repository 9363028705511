import classnames from 'classnames';
import * as React from 'react';

import * as styles from './CollapsedBlock.css';
import { CollapsedButton } from './CollapsedButton';
import { WithChangeOrientation } from '../WithChangeOrientation/shared';

const DEFAULT_COLLAPSED_HEIGHT = 96;

interface ICollapsedBlockProps {
  isOpen?: boolean;
  collapsedHeight?: number;
  className?: string;
  withShadow?: boolean;
  onChangeOpen?(isOpen: boolean, heightDiff?: number): void;
  children?: React.ReactNode;
}

interface ICollapsedBlockState {
  isOpen: boolean;
  contentHeight: number;
}

export class CollapsedBlock extends React.PureComponent<ICollapsedBlockProps, ICollapsedBlockState> {
  public static defaultProps: Partial<ICollapsedBlockProps> = {
    collapsedHeight: DEFAULT_COLLAPSED_HEIGHT,
  };

  private contentEl = React.createRef<HTMLDivElement>();

  public constructor(props: ICollapsedBlockProps) {
    super(props);

    this.state = {
      isOpen: props.isOpen || false,
      contentHeight: props.collapsedHeight as number,
    };
  }

  public componentDidUpdate(prevProps: ICollapsedBlockProps, prevState: ICollapsedBlockState) {
    const { isOpen } = this.state;

    if (prevState.isOpen !== isOpen) {
      const { onChangeOpen, collapsedHeight } = this.props;

      if (onChangeOpen) {
        const diff = this.state.contentHeight - (collapsedHeight as number);

        onChangeOpen(isOpen, diff);
      }
    }
  }

  public render() {
    if (!this.props.children) {
      return null;
    }

    const { children, className, collapsedHeight, withShadow = true } = this.props;
    const { isOpen, contentHeight } = this.state;
    const collapsed = this.isExpanderVisible() && !isOpen;
    const contentClasses = classnames(styles['content'], collapsed && withShadow && styles['with-shadow']);
    const contentStyle = { maxHeight: `${collapsed ? collapsedHeight : contentHeight}px` };

    return (
      <WithChangeOrientation handler={this.updateContentHeight}>
        <div className={className} data-name="CollapsedBlock">
          <div className={contentClasses} style={contentStyle}>
            <div ref={this.contentEl}>{children}</div>
          </div>

          {this.isExpanderVisible() && <CollapsedButton isOpen={isOpen} onClick={this.toggleCollapsed} />}
        </div>
      </WithChangeOrientation>
    );
  }

  public updateContentHeight = () => {
    const contentEl = this.contentEl.current;

    if (!contentEl) {
      return;
    }

    const { height } = contentEl.getBoundingClientRect();

    this.setState({ contentHeight: height });
  };

  private isExpanderVisible() {
    return this.state.contentHeight > (this.props.collapsedHeight as number);
  }

  private toggleCollapsed = () => this.setState(state => ({ isOpen: !state.isOpen }));
}
