import { ICatalogs } from '../../types/catalogs';
import { TReduxActions } from '../../types/redux';

export const defaultState = {
  items: [],
};

export function catalogsReducer(state: ICatalogs = defaultState, action: TReduxActions): ICatalogs {
  switch (action.type) {
    default:
      return state;
  }
}
