import * as React from 'react';

import * as styles from './QuickLinks.css';
import { IQuickLinkProps } from './components/QuickLink';

export interface IQuickLinksProps {
  backgroundImage?: string;
  url: string;
  title: string;
  children: React.ReactElement<IQuickLinkProps> | React.ReactElement<IQuickLinkProps>[];
  onClick(): void;
}

const QuickLinksComponent: React.FC<IQuickLinksProps> = ({ backgroundImage, url, title, children, onClick }) => {
  return (
    <section className={styles['container']}>
      {backgroundImage && (
        <div className={styles['image']} style={{ backgroundImage: backgroundImage && `url(${backgroundImage})` }} />
      )}
      <div className={styles['content']}>
        <h2 className={styles['title']}>
          <a className={styles['link']} href={url} onClick={onClick}>
            {title}
          </a>
        </h2>
        <ul className={styles['list']}>{children}</ul>
      </div>
    </section>
  );
};

export const QuickLinks = React.memo(QuickLinksComponent);
