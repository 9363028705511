import { UIText3 } from '@cian/ui-kit';
import { useInView } from '@cian/valuation-utils-component';
import * as React from 'react';

import * as styles from './Service.css';
import { default as defaultImage } from './assets/default_new_service.png';

interface IServiceProps {
  title: string;
  url: string;
  image?: string | null;
  onShow?(): void;
  onClick(event: React.MouseEvent<HTMLAnchorElement>): void;
}

export const Service: React.FC<IServiceProps> = ({ title, url, image, onShow, onClick }) => {
  const { ref, inView } = useInView({
    threshold: 0.75,
    triggerOnce: true,
  });

  React.useEffect(() => {
    if (inView && onShow) {
      onShow();
    }
  }, [inView, onShow]);

  return (
    <a ref={ref} href={url} className={styles['container']} onClick={onClick}>
      <img src={image || defaultImage} alt={title} />
      <UIText3>{title}</UIText3>
    </a>
  );
};
