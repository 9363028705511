import { IApplicationState, IPageState } from '../../types/redux';

const defaultState: IPageState = {
  platform: undefined,
  deviceType: 'mobile',
  isSafari: false,
};

export function pageReducer(state: IApplicationState['page'] = defaultState) {
  return state;
}
