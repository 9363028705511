import { ca } from '@cian/analytics';

interface ITrackQuickLinksProps {
  url: string;
}

export const getQuickLinksTracker = ({ url }: ITrackQuickLinksProps) => {
  return () =>
    ca('eventSite', {
      name: 'oldevent',
      category: 'mobile_main_page',
      action: 'click_fast_links',
      label: url,
    });
};
