import { createComponent } from '@cian/mf-react-legacy';
import { IParameters } from '@cian/mf-registry-legacy/shared';
import * as React from 'react';

import * as styles from './Recommendations.css';
import { RECOMMENDATIONS_MICROFRONTEND_NAME, RECOMMENTATIONS_MICROFRONTEND_PATH } from '../../constants/microfrontend';

interface IRecommendationsProps extends IParameters {
  regionId: number;
  landingType: string;
}

const RecommendationsComponent = createComponent<IRecommendationsProps>({
  mcs: RECOMMENDATIONS_MICROFRONTEND_NAME,
  path: RECOMMENTATIONS_MICROFRONTEND_PATH,
});

export const Recommendations: React.VFC<IRecommendationsProps> = props => (
  <div className={styles['container']}>
    <RecommendationsComponent {...props}></RecommendationsComponent>
  </div>
);
