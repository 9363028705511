import * as React from 'react';

import * as styles from './QuickLink.css';

export interface IQuickLinkProps {
  title: string;
  subtitle?: string | null;
  href: string;
  onClick(): void;
}

const QuickLinkComponent: React.FC<IQuickLinkProps> = ({ title, subtitle, href, onClick }) => {
  return (
    <li className={styles['container']}>
      <a href={href} onClick={onClick}>
        {title}
        {subtitle && <span className={styles['subtitle']}>{subtitle}</span>}
      </a>
    </li>
  );
};

export const QuickLink = React.memo(QuickLinkComponent);
