import { createConsumer, createSubscriber, IEvent } from '@cian/events-log';
import { useEffect } from 'react';

import { TRecommendationsEvent } from './types';
import {
  trackRecommendationsView,
  trackRecommendationItemClick,
  trackRecommendationTrapClick,
  trackRecommendationsTrapView,
} from '../tracking';

export const useRecommendationsEventsSubscribe = () => {
  useEffect(() => {
    const consumer = createConsumer({ topic: 'recommendations' });

    const recommendationsSubscriber = createSubscriber((event: IEvent<unknown>) => {
      const eventData = event as TRecommendationsEvent;

      switch (eventData.type) {
        case 'show':
          trackRecommendationsView(eventData.value);

          break;
        case 'click': {
          const { suffix, offerId } = eventData.value;

          if (offerId) {
            trackRecommendationItemClick(eventData.value.products);
          } else if (suffix) {
            trackRecommendationTrapClick(suffix);
          }

          break;
        }
        case 'showTrap': {
          const { suffix } = eventData.value || {};

          trackRecommendationsTrapView(suffix);

          break;
        }
      }
    });

    consumer.subscribe(recommendationsSubscriber);

    return () => {
      consumer.unsubscribe(recommendationsSubscriber);
      consumer.destroy();
    };
  });
};
