import { IApplicationState, IUserState } from '../../types/redux';

const defaultState: IUserState = {
  abGroup: 100,
  userId: null,
  // TODO: Безжалостно выпилить как только завершится эксперимент SPEC-348
  userType: null,
};

export function userReducer(state: IApplicationState['user'] = defaultState) {
  return state;
}
